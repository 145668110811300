import request from './request';

export const serviceURLFiles = 'Files/Api/v1/Files/';
export const serviceURLPics = 'Files/Api/v1/Pics/';
export const serviceURLDocuments = 'Files/Api/v1/Documents/';

export async function getDownloadFileParams(urlParams) {
  return request(`${serviceURLFiles}DownloadPaymentFile/${urlParams}`, {
    method: 'GET'
  });
}

export async function uploadFile(data) {
  return request(`${serviceURLFiles}`, {
    method: 'POST',
    data
  });
}

export async function getProofs(userId){
  return request(`${serviceURLFiles}GetProofs/voucher/1/${userId}`, {
    method: 'GET'
  });
}

export async function getGlobalFile({mainFolder = "", docType = 0, foreignId = ""}){
  return request(`${serviceURLFiles}GetFile/${mainFolder}/${docType}/${foreignId}`, {
    method: 'GET'
  });
}

export async function addProofs(file, userId){
  return request(`${serviceURLFiles}BuyerProofs/${userId}`, {
    method: 'POST',
    data: file
  });
}

export async function changeProofType(userId, documentTypeId){
  return request(`${serviceURLFiles}UpdateProofType/${userId}/${documentTypeId}`, {
    method: 'PUT'
  });
};

export async function deleteProof(fileId){
  return request(`${serviceURLFiles}DeleteProof/${fileId}`, {
    method: 'DELETE'
  });
}
export async function uploadFileResource(data) {  
  return request(`${serviceURLFiles}SaveFileResource`, {
    method: 'POST',
    data,
  });
}

export async function getFileResourceList(type) {
  return request(`${serviceURLFiles}GetFileResourceList/${type}`, {
    method: 'GET'
  });
}

export async function deleteFileResource(fileId) {
  return request(`${serviceURLFiles}DeleteFileResource/${fileId}`, {
    method: 'DELETE'
  });
}

export async function getFileResource(fileId) {
  return request(`${serviceURLFiles}GetFileResource/${fileId}`, {
    method: 'GET'
  });
}

export async function getZipPictures(caseId, sectionId, onlyFavorite = true, allInRoot = false, advertId = null) {
  return request(`${serviceURLPics}LastPics/zip/${caseId}?sectionId=${sectionId}&onlyFavorite=${onlyFavorite}&allInRoot=${allInRoot}&advertId=${advertId}`, {
    method: 'GET',
  });
}

export async function addMassiveDocumentsValidate(data) {
  return request(`${serviceURLDocuments}MassLoadValidateCaseComments`, {
    method: 'POST',
    data
  });
}

export async function addMassiveDocuments(data) {
  return request(`${serviceURLDocuments}MassLoadCaseComments`, {
    method: 'POST',
    data
  });
}

