import request from './request';
import { serviceURLFiles } from './files';
var qs = require('qs');

export async function forceDownloadFile(url, params) {
  return request(`${url}`, {
    params,
    method: 'GET',
    responseType: 'blob',
    paramsSerializer: param => {
      return qs.stringify(param, { arrayFormat: 'repeat' });
    }
  });
}

export async function downloadFileToPreview(url, params) {
  return request(`${url}`, {
    params,
    method: 'GET'
  });
}

export async function forceDownloadFileURL(url) {
  return request(`${url}`, {
    method: 'GET'
  });
}

export async function downloadDirectory({ caseId, documentTypeId, fileName }) {
  return request(`${serviceURLFiles}DownloadDirectory/${caseId}/${documentTypeId}`, {
    method: 'GET',
    responseType: 'blob'
  }).then(response => {
    if (typeof response !== 'undefined' && response.status === 200) {
      const type = response.headers['content-type'];
      const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      return true;
    } else {
      return false;
    }
  });
}
