import axios from 'axios';
import { notification } from 'antd';

import autoStore from 'redux/store';
import { requestLogOut } from 'redux/actions/authActions';
import { sendNotification } from 'helpers/helperNotifications';
import { objectHasProperty, cleanLocalStorage } from 'utils';
import { messageCodeHTTP } from 'models/enums';

let instance = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_API_GATEWAY,
  validateStatus: function(status) {
    return status < 500;
  }
});
let endPoint = null;
let isEnabled = false;

instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;
//instance.defaults.timeout = 93000;
instance.defaults.timeout = 1800000;
instance.defaults.headers.common['Accept'] = 'application/json';
instance.defaults.headers.put['Content-Type'] = 'application/json;';
instance.defaults.headers.post['Content-Type'] = 'application/json;';
instance.defaults.headers.post['Access-control-Allow-Origin'] = '*';

// need interceptor
const isHandlerEnabled = config => {
  return objectHasProperty(config, 'notInterceptor') ? true : false;
};

const requestHandler = request => {
  isEnabled = isHandlerEnabled(request);

  try {
    let token = localStorage.getItem('tkn');
    if (token && token !== '' && !request.notBearer) {
      request.headers.common['Authorization'] = `Bearer ${token}`;
    }
  } catch (_error) {
    delete request.headers.common['Authorization'];
  }

  return request;
};

const showError = endPoint => {
  sendNotification(
    'error',
    'Ocurrió un error en el servicio',
    'Verfique su conexión a Internet y actualice esta página'
  ); // + Buffer.from(endPoint, 'base64'));
};
//request interceptors
instance.interceptors.request.use(request => {
  endPoint = request.url;
  return requestHandler(request);
});
//response interceptors
instance.interceptors.response.use(
  response => {
    if (isEnabled) {
      return response;
    }

    const { status } = response;

    if (typeof response !== 'undefined') {
      const httpStatusText = messageCodeHTTP[status];
      const errorServer = response.data.message || '';
      switch (status) {
        case 200:
          if (response.data.notInterceptor) {
            return response;
          }
          break;
        case 301:
          sendNotification('warning', errorServer, 'Moved Permanently');
          break;
        case 400:
          notification.destroy();
          if (response.data.notInterceptor) {
            return response;
          }

          if (response.data && errorServer.length > 0) {
            if (
              typeof response.data.errorList !== 'undefined' &&
              response.data.errorList.length > 0
            ) {
              response.data.errorList.forEach(e => {
                sendNotification('error', e);
              });
            } else {
              sendNotification('error', errorServer, httpStatusText);
            }
          }

          if (!response.data) {
            if (errorServer.length > 0) {
              sendNotification('error', errorServer);
            }
          }
          if (objectHasProperty(response.data, 'exception') && response.data.exception) {
            console.error('Error with an exception  ', response.data.exception);
            if (response.data.message === '') showError(endPoint);
          }
          console.error('The endpoint with error is: ', endPoint);
          break;
        case 401:
          sendNotification('info', 'Su token ha expirado.');
          instance.defaults.headers.common['Authorization'] = null;
          autoStore.dispatch(requestLogOut());
          cleanLocalStorage();
          break;
        case 404:
          sendNotification('error', ` ${status}: Servicio no encontrado`, httpStatusText);
          break;
        default:
          sendNotification('error', ` ${status}: ${errorServer}`, httpStatusText);
          break;
      }
    } else {
      notification.destroy();
      console.error('Response not defined', response);
      console.error('The endpoint with error is: ', endPoint);
    }

    return response;
  },
  error => {
    notification.destroy();

    if (navigator.onLine) {
      if (instance.isCancel(error)) {
        console.warn("Aborted request controlled, don't worry");
      } else {
        showError(endPoint);
        console.error('The endpoint with error is:  ', endPoint);
      }
    } else {
      sendNotification('error', 'Sín conexión a internet', 'not network');
    }
    //return Promise.reject({ error });
  }
);

export default instance;
