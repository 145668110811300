import request from './request';
const serviceURL = 'Cases/Api/v1/Cases/';
const serviceURLDocuments = 'Files/Api/v1/Documents/';
const serviceURLPics = 'Files/Api/v1/Pics/';
const serviceURLFiles = 'Files/Api/v1/Files/';
const serviceURLAudatex = 'Cases/Api/v1/Audatex/';
var qs = require('qs');

const serviceURLHistory = 'Inventories/Api/v1/Inventories/History/';

export async function getCheckoutHistory(params) {
  const { caseId } = params;
  return request(`${serviceURLHistory}${caseId}`, {
    method: 'GET'
  });
}

export async function searchCases(params) {
  return request(`${serviceURL}MatchCase`, {
    params,
    method: 'GET',
    transformResponse: [
      function(data) {
        let current = JSON.parse(data);
        let customResponse = {
          ...current
          // notInterceptor: true
        };
        return customResponse;
      }
    ]
  });
}

export async function searchCasesService(params) {
  return request(`${serviceURL}`, {
    params,
    paramsSerializer: (param) =>{
      return qs.stringify(param, {arrayFormat: 'repeat'})
    },
    method: 'GET'
  });
}

export async function getCase(caseId, cancelToken) {
  return request(`${serviceURL}${caseId}`, {
    method: 'GET',
    cancelToken
  });
}

export async function getDocumentsCase(caseId, documentTypeId, cancelToken) {
  let documentTypeExist =
    typeof documentTypeId !== 'undefined' && documentTypeId ? documentTypeId : '';
  return request(`${serviceURLDocuments}${caseId}/${documentTypeExist}`, {
    method: 'GET',
    cancelToken
  });
}
//31  únicamente para los archivos referentes a diversos
export async function getFilesFromDiverseCase(caseId, cancelToken) {
  return request(`${serviceURLFiles}`, {
    params: { caseId, documentTypeId: 31 },
    method: 'GET',
    cancelToken
  });
}

export async function updateDocumentsCase(caseId, arrayDocuments) {
  return request(`${serviceURLDocuments}${caseId}`, {
    data: arrayDocuments,
    method: 'PUT'
  });
}
export async function updateObservationsDocuments(data) {
  return request(`${serviceURLDocuments}UpdateComments`, {
    data,
    method: 'PUT'
  });
}

export async function getFilesCase(params) {
  //caseId,documentTypeId
  return request(`${serviceURLFiles}`, {
    params,
    method: 'GET'
  });
}

export async function updateStatusFile(params) {
  return request(`${serviceURLFiles}`, {
    data: params,
    method: 'PUT'
  });
}

export async function uploadFile(data) {
  return request(`${serviceURLFiles}`, {
    method: 'POST',
    data
  });
}
export async function getPhotosCase(caseId) {
  //caseId
  return request(`${serviceURLPics}${caseId}`, {
    method: 'GET'
  });
}

//TODO: Deprectaed use only one param
export async function getDownloadFile(caseId, fileId, base64) {
  return request(`${serviceURLFiles}Download/${caseId}/${fileId}/${base64}`, {
    method: 'GET'
  });
}

export async function getDownloadFileNew(path) {
  return request(`${serviceURLFiles}Download/${path}`, {
    method: 'GET'
  });
}

export async function forceDownloadFile(eventId, fileId, name) {
  return request(`${serviceURLFiles}Download/${eventId}/${fileId}/${true}`, {
    method: 'GET',
    responseType: 'blob'
  }).then(response => {
    if (typeof response !== 'undefined' && response.status === 200) {
      const type = response.headers['content-type'];
      const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = name;
      link.click();
      return true;
    } else {
      return false;
    }
  });
}

export async function deleteFile(caseId, fileId, cancelToken) {
  return request(`${serviceURLFiles}${caseId}/${fileId}`, {
    method: 'DELETE',
    cancelToken
  });
}

export async function validateMassiveCases(data) {
  return request(`${serviceURL}MassLoadValidate`, {
    method: 'POST',
    data
  });
}

export async function addMassiveCases(data) {
  return request(`${serviceURL}MassLoad`, {
    method: 'POST',
    data
  });
}

export async function addMultipleZipsVehicles(data) {
  return request(`${serviceURLFiles}Zip/Vehicles`, {
    method: 'POST',
    data
  });
}

export async function addMultipleZipsProducts(data) {
  return request(`${serviceURLFiles}Zip/Products`, {
    method: 'POST',
    data
  });
}

export async function getCasesAudatex(params) {
  return request(`${serviceURLAudatex}`, {
    params,
    method: 'GET',
    paramsSerializer: param => {
      return qs.stringify(param, { arrayFormat: 'repeat' });
    }
  });
}

export async function selectVehicleAudatex(recordId) {
  return request(`${serviceURLAudatex}SelectVehicle/${recordId}`, {
    method: 'GET'
  });
}

export async function getPhotosAudatex(importedVehicleId) {
  return request(`${serviceURLAudatex}Pics/${importedVehicleId}`, {
    method: 'GET'
  });
}

export async function createVehicleCase(data) {
  return request(`${serviceURL}VehicleCase`, {
    method: 'POST',
    data
  });
}

export async function updateVehicleCase(data) {
  return request(`${serviceURL}VehicleCase`, {
    method: 'PUT',
    data
  });
}

export async function changeStatusCase(caseId) {
  return request(`${serviceURL}UpdateStatus/${caseId}`, {
    method: 'PUT'
  });
}

export async function getCaseIdByFileName(fileName) {
  return request(`${serviceURLFiles}CaseByFile/${fileName}`, {
    method: 'GET'
  });
}

export async function deleteCase(data) {
  return request('Cases/Api/v1/Cases', {
    method: 'DELETE',
    data
  });
}

export async function uploadCasePic(data) {
  return request(`${serviceURLPics}`, {
    method: 'POST',
    data,
    transformResponse: [
      function(d) {
        let current = JSON.parse(d);
        let customResponse = {
          ...current,
          notInterceptor: true
        };
        return customResponse;
      }
    ]
  });
}

export async function deleteCasePic(payload) {
  const { caseId, fileId } = payload;
  return request(`${serviceURLPics}${caseId}/${fileId}`, {
    method: 'DELETE'
  });
}

export async function favCasePic(payload) {
  const { caseId, fileId, asFavorite, fromAll } = payload;
  return request(`${serviceURLPics}Favorite/${caseId}/${fileId}${fromAll ? "?asFavorite=" + asFavorite : ""}`, {
    method: 'PUT'
  });
}

export async function coverCasePic(payload) {
  const { caseId, fileId } = payload;
  return request(`${serviceURLPics}Cover/${caseId}/${fileId}`, {
    method: 'PUT'
  });
}

export async function updateProductCase(data) {
  return request(`${serviceURL}ProductCase`, {
    method: 'PUT',
    data
  });
}

export async function approveAllFiles(data) {
  return request(`${serviceURLFiles}ValidateAll`, {
    method: 'PUT',
    data
  });
}

export async function downloadCaseDiverseFile(urlParams) {
  return request(`${serviceURLFiles}Download/${urlParams}`, {
    method: 'GET',
  })
}

export async function getLastPics(caseId, transferId) {
  let result;
  if (transferId) {
    result = request(`${serviceURLPics}LastPics/${caseId}/${transferId}`, {
      method: 'GET'
    });
  } else if (caseId) {
    result = request(`${serviceURLPics}LastPics/${caseId}`, {
      method: 'GET'
    });
  }

  return result;
}

export async function saveVideoUrl(caseId, data){
  return request(`${serviceURL}VideoUrl/${caseId}`, {
    method: 'POST',
    data
  });
}

export async function getVideoUrl(caseId){
  return request(`${serviceURL}VideoUrl/${caseId}`, {
    method: 'GET'
  });
};

export async function deleteVideoUrl(videoId){
  return request(`${serviceURL}VideoUrl/${videoId}`, {
    method: 'DELETE'
  });
}

export async function getCaseLogs(caseId){
  return request(`${serviceURL}GetCaseLog/${caseId}`, {
    method: 'GET'
  });
}
