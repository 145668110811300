import request from './request';
const advertsURL = 'Adverts/Api/v1/Adverts';
var qs = require('qs');

export async function getAdverts(params) {
  return request(`${advertsURL}`, {
    params,
    method: 'GET',
    paramsSerializer: param => {
      return qs.stringify(param, { arrayFormat: 'repeat' });
    }
    // transformResponse: [
    //   function(data) {
    //     let current = JSON.parse(data);
    //     let customResponse = {
    //       ...current,
    //       notInterceptor: !emptyFields
    //     };
    //     return customResponse;
    //   }
    // ]
  });
}

export async function postAdvert(data) {
  return request(`${advertsURL}`, {
    method: 'POST',
    data
  });
}

export async function getAdvertByCase(caseId) {
  return request(`${advertsURL}/ByCase/${caseId}`, {
    method: 'GET'
  });
}

export async function getAdvertById(advertId) {
  return request(`${advertsURL}/${advertId}`, {
    method: 'GET'
  });
}

export async function updateAdvert(advertId, data) {
  return request(`${advertsURL}/${advertId}`, {
    method: 'PUT',
    data
  });
}

export async function updateCaseCosts(data) {
  return request(`${advertsURL}/cases`, {
    method: 'PUT',
    data
  });
}

export async function deleteAdvert(advertId) {
  return request(`${advertsURL}${advertId}`, {
    method: 'DELETE'
  });
}

export async function engageCase(data) {
  return request(`${advertsURL}/cases/Engage`, {
    method: 'POST',
    data
  });
}

export async function disengageCase(data) {
  return request(`${advertsURL}/cases/Disengage`, {
    method: 'PUT',
    data
  });
}

export async function activateAdvert(data) {
  return request(`${advertsURL}/cases/Activate`, {
    method: 'PUT',
    data
  });
}

export async function getCasesToAdverts(params, cancelToken) {
  return request(`${advertsURL}/cases`, {
    params,
    method: 'GET',
    cancelToken
  });
}

export async function searchPreAdvertsCases(params, cancelToken) {
  return request(`${advertsURL}/cases/over`, {
    params,
    method: 'GET',
    cancelToken
  });
}

export async function checkCaseToAdvert(data, cancelToken) {
  return request(`${advertsURL}/cases/Mark/`, {
    method: 'PUT',
    data,
    cancelToken
  });
}
export async function notifyBuyers(data, cancelToken) {
  return request(`${advertsURL}/Notifications`, {
    method: 'POST',
    data,
    cancelToken
  });
}

export async function changeStatusAdvert(id, cancelToken) {
  return request(`${advertsURL}/${id}`, {
    method: 'DELETE',
    cancelToken
  });
}

export async function getAdvertsResults(advertId, caseId, cancelToken) {
  return request(`${advertsURL}/Results/${advertId}/${caseId}`, {
    method: 'GET',
    cancelToken
  });
}
