import i18next from 'i18next';

import request from './request';

const serviceURL = 'Auth/api/v1/authenticate';
const servicePass = 'Auth/api/v1/passwords';


export async function login(params, method) {
  return request(serviceURL + `?language=${i18next.language}`, {
    method,
    data: params
  });
}

export async function logout() {
  return request(serviceURL, {
    method: 'GET',
    transformResponse: [
      function (data) {
        let current = JSON.parse(data);
        let customResponse = {
          ...current,
          notInterceptor: true
        };
        return customResponse;
      }
    ]
  });
}

export async function refresh(token, method) {
  return request(serviceURL + `?language=${i18next.language}`, {
    method,
    data: { token }
  });
}

export async function forceLogout(params) {
  return request(`${serviceURL}/ForceLogout?language=${i18next.language}`, {
    method: 'POST',
    data: params,
    notBearer: true
  });
}

export async function validSession(params) {
  return request(`${serviceURL}/ValidSession?language=${i18next.language}`, {
    method: 'POST',
    data: params,
    notBearer: true
  });
}

export async function getChangePassword(data) {
  const response = await request(`${servicePass}`, {
    method: 'PUT',
    data: data,
    transformResponse: [
      function (d) {
        let current = JSON.parse(d);
        let customResponse = {
          ...current,
          notInterceptor: true
        };
        return customResponse;
      }
    ]
  });
  return response;
}

export async function getResetPassword(data) {
  const response = await request(`${servicePass}/Restore?language=${i18next.language}`, {
    method: 'PUT',
    data: data
  });
  return response;
}

export async function getActivationPassword(data) {
  const response = await request(`${servicePass}/activation/${data.userId}?language=${i18next.language}`, {
    method: 'PUT',
    data: data
  });
  return response;
}
