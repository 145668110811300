import request from './request';

const serviceURL = 'Transfers/api/v1/transfers';
const serviceBase = 'Transfers/api/v1/TransfersReports';
var qs = require('qs');

export async function getTransfers(params) {
  return request(`${serviceURL}`, {
    params,
    method: 'GET',
    paramsSerializer: param => {
      return qs.stringify(param, { arrayFormat: 'repeat' });
    }
    // transformResponse: [
    //   function(data) {
    //     let current = JSON.parse(data);
    //     let customResponse = {
    //       ...current,
    //       notInterceptor: !emptyFields
    //     };
    //     return customResponse;
    //   }
    // ]
  });
}

export async function updateTransfers(data) {
  return request(`${serviceURL}`, {
    method: 'PUT',
    data
  });
}

export async function getTransferData(caseId) {
  return request(`${serviceURL}/Build/${caseId}`, {
    method: 'GET'
  });
}

export async function getByTransferId(transferId) {
  return request(`${serviceURL}/${transferId}`, {
    method: 'GET'
  });
}

export async function checkTransferStatus(caseId, cancelToken) {
  return request(`${serviceURL}/CheckCase/${caseId}`, {
    method: 'GET',
    cancelToken
  });
}

export async function getTransferAction(cancelToken) {
  return request(`${serviceURL}/Actions`, {
    method: 'GET',
    cancelToken
  });
}

export async function sendTransferAction(transferActionRequest) {
  return request(`${serviceURL}/Actions`, {
    method: 'POST',
    data: transferActionRequest
  });
}

export async function assingTransfer(data) {
  return request(`${serviceURL}`, {
    method: 'POST',
    data: data
  });
}

export async function assingTransferMassive(data) {
  return request(`${serviceURL}/Mass`, {
    method: 'POST',
    data: data
  });
}

export async function transfersActions(params) {
  return request(`${serviceURL}/Actions`, {
    params,
    method: 'GET'
  });
}

export async function getTransferByCaseId(caseId) {
  return request(`${serviceURL}/ByCase/${caseId}`, {
    method: 'GET'
  });
}

export async function getTransferFiles(movementId) {
  return request(`${serviceURL}/Files/${movementId}`, {
    method: 'GET'
  });
}

export async function getCaseTransferResume(caseId) {
  return request(`${serviceURL}/Resume/${caseId}`, {
    method: 'GET'
  });
}

export async function postTransferPhoto(movementId, data) {
  return request(`${serviceURL}/Pics/${movementId}`, {
    method: 'POST',
    data: data
  });
}

export async function getFiles(movementId) {
  return request(`${serviceURL}/Files/${movementId}`, {
    method: 'GET'
  });
}

export async function deletePic(movementId, fileId) {
  return request(`${serviceURL}/Pics/${movementId}/${fileId}`, {
    method: 'DELETE'
  });
}
export async function getTransferReport({ params, cancelToken }) {
  return request(`${serviceBase}/ReportResume?`, {
    method: 'GET',
    cancelToken,
    params,
    paramsSerializer: param => {
      return qs.stringify(param, { arrayFormat: 'repeat' });
    }
  });
}

export async function reportTransferQueued(data){
  return request(`${serviceBase}/ReportQueued`, {
    method: 'POST',
    data
  });
}
