import React, { Suspense } from 'react';
import { Switch } from 'react-router-dom';
import HandleRoutes from './HandleRoutes';
import routes from 'routes/routesMap';
import PropTypes from 'prop-types';
import Login from 'pages/Login';
// import ForgetPassword from 'components/ForgotPasswordForm';
import ExternalInsured from 'pages/ExternalInsured';
import ExternalBuyer from 'pages/ExternalBuyer';
import ResetPasswordPage from 'pages/ResetPassword';
import NotFound from '../pages/NotFound';

// const Login = lazy(() => retry(() => import('../pages/Login')));
// const ForgetPassword = lazy(() => retry(() => import('components/ForgotPasswordForm')));
// const ExternalInsured = lazy(() => retry(() => import('../pages/ExternalInsured')));
// const ExternalBuyer = lazy(() => retry(() => import('../pages/ExternalBuyer')));

export default function Routes({ isAuthenticated: isAuth }) {
  return (
    <Suspense>
      <Switch>
        {/**start public routes */}
        <HandleRoutes isAuthenticated={isAuth} path="/" exact component={Login} />
        <HandleRoutes isAuthenticated={isAuth} path="/login" component={Login} />
        <HandleRoutes
          isAuthenticated={false}
          path="/insured/external"
          externalRegister={true}
          component={ExternalInsured}
        />

        <HandleRoutes
          isAuthenticated={false}
          path="/buyer/external/:token"
          externalRegister={true}
          component={ExternalBuyer}
        />
        {/* <HandleRoutes isAuthenticated={false} path="/forgotPassword" component={ForgetPassword} /> */}
        <HandleRoutes
          isAuthenticated={false}
          path="/activation/:id"
          externalRegister={true}
          component={ResetPasswordPage}
        />
        {/**end public routes */}
        {routes &&
          routes.length > 0 &&
          routes.map(({ path, Component, ...rest }, key) => (
            <HandleRoutes
              key={key}
              path={path}
              isAuthenticated={isAuth}
              component={Component}
              isPrivate={true}
              {...rest}
            />
          ))}

        <HandleRoutes isAuthenticated={isAuth} component={Login} />
        <HandleRoutes isAuthenticated={isAuth} component={NotFound} path="*" isPrivate={true} />
      </Switch>
    </Suspense>
  );
}

Routes.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired
};

Routes.defaultProps = {
  isAuthenticated: false
};
