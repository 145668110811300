import request from './request';
const serviceURL = 'Users/api/v1/users/';
const serviceURLFiles = 'Files/Api/v1/Files/';
var qs = require('qs');

export async function getUserComments(userId) {
  return request(`${serviceURL}CommentRecord?Orderby=false&UserId=${userId}`, {
    method: 'GET'
  });
}

export async function listRoles() {
  return request(`${serviceURL}roles/roleGroups`, {
    method: 'GET'
  });
}

export async function getMenu() {
  return request(`${serviceURL}roles/Menu`, {
    method: 'GET'
  });
}

export async function listProfiles(id) {
  return request(`${serviceURL}roles/${id}`, {
    method: 'GET',
    transformResponse: [
      function (data) {
        let current = JSON.parse(data);
        let customResponse = {
          ...current,
          notInterceptor: true
        };
        return customResponse;
      }
    ]
  });
}

export async function getAssociatedBuyerUsers(userId){
  return request(`${serviceURL}Buyers/GetAssociatedBuyers/${userId}`, {
    method: 'GET'
  });
}

export async function getUserCommentsPostgres(userId) {
  return request(`${serviceURL}UserComment?Orderby=false&userId=${userId}`, {
    method: 'GET'
  });
};

export async function addUser(payload) {
  return request(`${serviceURL}${payload.userType}`, {
    method: 'POST',
    data: payload.user,
    transformResponse: [
      function (d) {
        let current = JSON.parse(d);
        let customResponse = {
          ...current,
          notInterceptor: true
        };
        return customResponse;
      }
    ]
  });
}

export async function addAssociatedBuyer(parentId, data){
  return request(`${serviceURL}Buyers/CreateAssocitedBuyer/${parentId}`, {
    method: 'POST',
    data
  });
}

export async function updateAssociatedBuyer(userId, data){
  return request(`${serviceURL}Buyers/UpdateAssocitedBuyer/${userId}`, {
    method: 'PUT',
    data
  });
}

export async function updateUser(user, type) {
  return request(`${serviceURL}${type}`, {
    method: 'PUT',
    data: user,
    transformResponse: [
      function (d) {
        let current = JSON.parse(d);
        let customResponse = {
          ...current,
          notInterceptor: true
        };
        return customResponse;
      }
    ]
  });
}

export async function validateMassiveUsers(data, serviceType) {
  return request(`${serviceURL}${serviceType}/MassLoadValidate`, {
    method: 'POST',
    data
  });
}

export async function addMassiveUsers(data, serviceType) {
  return request(`${serviceURL}${serviceType}/MassLoad`, {
    method: 'POST',
    data
  });
}

export async function insuredList() {
  return request(`${serviceURL}InsPerson/ForValidate`, {
    method: 'GET'
  });
}

export async function sendSingleInvitation(data) {
  return request(`${serviceURL}Prospects`, {
    method: 'POST',
    data: data
  });
}

export async function validateProspectsMassLoad(data) {
  return request(`${serviceURL}Prospects/MassLoadValidate`, {
    method: 'POST',
    data: data
  });
}

export async function prospectsMassLoad(data) {
  return request(`${serviceURL}Prospects/MassLoad`, {
    method: 'POST',
    data: data
  });
}

export async function changeStatusInsured(user) {
  return request(`${serviceURL}InsPerson/Validation`, {
    method: 'PUT',
    data: user,
    transformResponse: [
      function (data) {
        let current = JSON.parse(data);
        let customResponse = {
          ...current,
          notInterceptor: true
        };
        return customResponse;
      }
    ]
  });
}

export async function validateBuyer(user) {
  return request(`${serviceURL}Buyers/Validation`, {
    method: 'PUT',
    data: user
  });
}

export async function getAdminInfoById(userId) {
  return request(`${serviceURL}Admins/${userId}`, {
    method: 'GET'
  });
}

export async function getBuyerInfoById(userId) {
  return request(`${serviceURL}Buyers/${userId}`, {
    method: 'GET'
  });
}

export async function getInsCarrierInfoById(userId) {
  return request(`${serviceURL}InsCarriers/${userId}`, {
    method: 'GET'
  });
}

export async function getInsPersonInfoById(userId) {
  return request(`${serviceURL}InsPerson/${userId}`, {
    method: 'GET'
  });
}

export async function getProviderInfoById(userId) {
  return request(`${serviceURL}Providers/${userId}`, {
    method: 'GET'
  });
}

export async function getUsers(params) {
  return request(`${serviceURL}`, {
    params,
    method: 'GET',
    paramsSerializer: param => {
      return qs.stringify(param, { arrayFormat: 'repeat' });
    },
    transformResponse: [
      function (data) {
        let current = JSON.parse(data);
        let customResponse = {
          ...current,
          notInterceptor: true
        };
        return customResponse;
      }
    ]
  });
}

/* /Api/v1/Users/Myself  */
export async function getMyProfileInfo(cancelToken) {
  return request(`${serviceURL}Myself`, {
    method: 'GET',
    cancelToken
  });
}

export async function setProfilePhoto(data) {
  return request(`${serviceURL}Myself`, {
    method: 'POST',
    data: data
  });
}

export async function getBuyersForValidation(params) {
  return request(`${serviceURL}Buyers/ForValidate`, {
    params,
    method: 'GET'
  });
}

export async function buildExternalBuyerReg(key, isCompany) {
  return request(`${serviceURL}Buyers/Build/${key}/${isCompany}`, {
    method: 'GET'
  });
}

export async function registerExternalBuyer(inviteId, data) {
  return request(`${serviceURL}Buyers/Buyer/${inviteId}`, {
    method: 'POST',
    data: data
  });
}

export async function registerExternalBuyerCompany(inviteId, data, type) {
  return request(`${serviceURL}${type}/${inviteId}`, {
    method: 'POST',
    data: data,
    transformResponse: [
      function (d) {
        let current = JSON.parse(d);
        let customResponse = {
          ...current,
          notInterceptor: true
        };
        return customResponse;
      }
    ]
  });
}

export async function getUserFiles(userId, fileId, data) {
  return request(`${serviceURL}Files/Download/${userId}/${fileId}`, {
    method: 'GET',
    data: data
  });
}

export async function searchInsPersonForValidate(params) {
  return request(`${serviceURL}InsPerson/ForValidate`, {
    params,
    method: 'GET'
  });
}

export async function graph() {
  return request(`${serviceURL}graph`, {
    method: 'GET'
  });
}

export async function addMultipleUserProofs(data) {
  return request(`${serviceURLFiles}Zip/Proofs`, {
    method: 'POST',
    data
  });
}

/* Change Password */
