import { blobToBase64 } from 'utils/helperActions';
import request from './request';
const buyersURL = 'Buyers/api/v1/';
const buyersByInsuranceURL = 'Users/Api/v1/users/Buyers/List/';
var qs = require('qs');

export async function getAdverts(params) {
  return request(`${buyersURL}Adverts`, {
    params,
    paramsSerializer: param => {
      return qs.stringify(param, { arrayFormat: 'repeat' });
    },
    method: 'GET',
    transformResponse: [
      function(data) {
        let current = JSON.parse(data);
        let customResponse = {
          ...current
        };
        return customResponse;
      }
    ]
  });
}

export async function getAdvertBuyerDetail(advertId, caseId, visits) {
  return request(`${buyersURL}Adverts/${advertId}/${caseId}/${visits}`, {
    method: 'GET'
  });
}

export async function markFav(data) {
  return request(`${buyersURL}Adverts/Mark`, {
    data: data,
    method: 'POST'
  });
}

export async function bidAdvert(data, cancelToken) {
  return request(`${buyersURL}bids`, {
    method: 'POST',
    data,
    cancelToken
  });
}

export async function getAwardingsList(params) {
  return request(`${buyersURL}Awardings/ByList`, {
    params,
    paramsSerializer: param => {
      return qs.stringify(param, { arrayFormat: 'repeat' });
    },
    method: 'GET',
    transformResponse: [
      function(data) {
        let current = JSON.parse(data);
        let customResponse = {
          ...current
        };
        return customResponse;
      }
    ]
  });
}

export async function getAwardingDetail(caseId) {
  return request(`${buyersURL}Awardings/${caseId}`, {
    method: 'GET'
  });
}

export async function uploadVoucher(data) {
  return request(`${buyersURL}Payments`, {
    method: 'POST',
    data
  });
}

export async function putVoucher(data) {
  return request(`${buyersURL}Files`, {
    method: 'PUT',
    data
  });
}

export async function deleteVoucher(paymentReferenceId, fileId) {
  return request(`${buyersURL}Payments/${paymentReferenceId}/${fileId}`, {
    method: 'DELETE'
  });
}

export async function getReferenceData(referenceId) {
  return request(`${buyersURL}Payments/Reference/${referenceId}`, {
    method: 'GET'
  });
}

export async function downloadFile(awardId, fileId) {
  return request(`${buyersURL}Files/Download/${awardId}/${fileId}`, {
    method: 'GET'
  });
}

export async function validateFile(awardId) {
  return request(`${buyersURL}Payments/SendToValidate/${awardId}`, {
    method: 'PUT'
  });
}

export async function getRejectComments(awardId, fileId) {
  return request(`${buyersURL}Payments/GetRejectedComment/${awardId}/${fileId}`, {
    method: 'GET'
  });
}

export async function downloadVehicleDirectory(caseId) {
  return request(`${buyersURL}Files/DownloadCarDirectory/${caseId}`, {
    method: 'GET',
    responseType: 'blob'
  }).then(response => {
    if (typeof response !== 'undefined' && response.status === 200) {
      const type = response.headers['content-type'];
      const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = caseId;
      link.click();
      return true;
    } else {
      return false;
    }
  });
}

export const getBuyersByInsuranceId = async insuranceCarrierId => {
  return request(`${buyersByInsuranceURL}${insuranceCarrierId}`, {
    method: 'GET'
  });
};

export const getPic = async (tokenPic, fileId, thumbnail) =>{
  try {
    const response = await fetch(`${process.env.REACT_APP_API_GATEWAY}/${buyersURL}Pics/${tokenPic}/${fileId}?thumbail=${thumbnail}`);
    const blob = await response.blob();
    const base64 = await blobToBase64(blob);
    return base64;
  } catch (error) {
  }
}

export const getThumbnailList = async (advertId, caseId, thumbnail = false) =>{
  return request(`${buyersURL}Files/GetPicsCases/${advertId}/${caseId}?thumbnail=${thumbnail}`,{
    method: 'GET'
  });
};
