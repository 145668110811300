import request from './request';
import { headerBase64PDF } from 'utils/constants';
const serviceURL = 'Deliveries/Api/v1/Deliveries';

export async function getDeliveries(caseId) {
  return request(`${serviceURL}/DeliveryComment/${caseId}`, {
    method: 'GET'
  });
}

export async function aknowledgementDelivery(data) {
  return request(`${serviceURL}/Acknowledgement/`, {
    method: 'POST',
    data
  });
}

export async function getDeliveryDetailBuyers(caseId) {
  return request(`${serviceURL}/Detail/${caseId}`, {
    method: 'GET'
  });
}

export async function postDeliveryComment(data) {
  return request(`${serviceURL}/`, {
    method: 'PUT',
    data
  });
}

export async function postDelivery(data) {
  return request(`${serviceURL}`, {
    method: 'POST',
    data
  });
}

export async function forceDownloadFile(data, name = 'Orden de entrega') {
  return request(`${serviceURL}`, {
    method: 'POST',
    data
  }).then(response => {
    if (typeof response !== 'undefined' && response.status === 200) {
      const link = document.createElement('a');
      const concat = `${headerBase64PDF}${response.data.data.content}`;
      link.download = name;
      link.href = concat;
      link.click();
      return response;
    } else {
      return false;
    }
  });
}

export async function getDeliveryDetail(caseId) {
  return request(`${serviceURL}/DetailInfo/${caseId}`, {
    method: 'GET'
  });
}
