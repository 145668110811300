import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import PublicLayout from '../components/Layout/PublicLayout';
import PrivateLayout from '../components/Layout/PrivateLayout';
import PublicRegister from '../components/Layout/PublicRegister';
import Loader from '../components/common/Loader';
import AccessControlPages from '../components/AccessControlPages';
import ErrorBoundary from 'components/ErrorBoundary';
export default function HandleRoutes({
  component: Component,
  isPrivate,
  isAuthenticated,
  externalRegister,
  ...rest
}) {
  let existExternal = typeof externalRegister !== 'undefined' ? externalRegister : false;
  if (isPrivate && !isAuthenticated && !existExternal) {
    return <Redirect to="/" />;
  }

  if (!isPrivate && isAuthenticated && !existExternal) {
    return <Redirect to="/dashboard" />;
  }

  let Layout = isAuthenticated ? PrivateLayout : PublicLayout;
  if (existExternal) {
    Layout = PublicRegister;
  }
  return (
    <Route
      {...rest}
      render={props => (
        <Layout externalRegister={existExternal}>
          <ErrorBoundary>
            <Suspense fallback={<Loader isPageLoader={true} />}>
              {Component && (
                <AccessControlPages
                  user={isAuthenticated}
                  hasAccessTo={rest.path}
                  child={<Component {...props} />}
                />
              )}
            </Suspense>
          </ErrorBoundary>
        </Layout>
      )}
    />
  );
}

HandleRoutes.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.any.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  externalRegister: PropTypes.bool.isRequired
};

HandleRoutes.defaultProps = {
  isPrivate: false,
  isAuthenticated: false,
  externalRegister: false
};
