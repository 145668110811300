import request from './request';
const serviceURL = 'Awardings/api/v1/Awardings';
const shortServiceURL = 'Awardings/api/v1';
var qs = require('qs');

export async function getAwardingsByAdvert(advertId, params) {
  const url = advertId ? `${serviceURL}/ByAdvert/${advertId}` : `${serviceURL}/ByAdvert`;
  return request(url, {
    params,
    method: 'GET',
    paramsSerializer: param => {
      return qs.stringify(param, { arrayFormat: 'repeat' });
    },
    transformResponse: [
      function(data) {
        let current = JSON.parse(data);
        let customResponse = {
          ...current
        };
        return customResponse;
      }
    ]
  });
}

export async function getAwardingsByList(params) {
  return request(`${serviceURL}/ByList`, {
    params,
    method: 'GET',
    paramsSerializer: param => {
      return qs.stringify(param, { arrayFormat: 'repeat' });
    },
    transformResponse: [
      function(data) {
        let current = JSON.parse(data);
        let customResponse = {
          ...current
        };
        return customResponse;
      }
    ]
  });
}

export async function deleteAward(advertId, caseId) {
  return request(`${serviceURL}/${advertId}/${caseId}`, {
    method: 'DELETE'
  });
}

export async function postDeadline(data) {
  return request(`${serviceURL}/Payments/MassUpdate`, {
    method: 'POST',
    data: data
  });
}

export async function getAwardByCase(caseId, cancelToken) {
  return request(`${serviceURL}/ByCase/${caseId}`, {
    method: 'GET',
    cancelToken
  });
}

export async function getBids(advertId, caseId, cancelToken) {
  return request(`${shortServiceURL}/Bids/${advertId}/${caseId}`, {
    method: 'GET',
    cancelToken
  });
}

export async function getAwardingCaseDetail(caseId, cancelToken) {
  return request(`${serviceURL}/${caseId}`, {
    method: 'GET',
    cancelToken
  });
}

export async function createAwarding(data, cancelToken) {
  return request(`${serviceURL}`, {
    method: 'POST',
    data,
    cancelToken
  });
}

export async function validateAwarding(data, cancelToken) {
  return request(`${serviceURL}/validate`, {
    method: 'PUT',
    data,
    cancelToken
  });
}

export async function awardHistory(awardId, cancelToken) {
  return request(`${serviceURL}/Comments/${awardId}`, {
    method: 'GET',
    cancelToken
  });
}

/**payments */
export async function searchPayments({ params, cancelToken }) {
  return request(`${serviceURL}/Payments`, {
    params,
    paramsSerializer: param =>{
      return qs.stringify(param, { arrayFormat: 'repeat' })
    }
  },{
    method: 'GET',
    params,
    cancelToken
  });
}

export async function paymentDetail(awardId, cancelToken) {
  return request(`${serviceURL}/Payments/${awardId}`, {
    method: 'GET',
    cancelToken
  });
}

export async function validatePaymentFile(data, cancelToken) {
  return request(`${serviceURL}/Payments/ValidatePaymentFile`, {
    method: 'PUT',
    data,
    cancelToken
  });
}

export async function validatePaymentResume(data, cancelToken) {
  return request(`${serviceURL}/Payments/ValidatePaymentFile`, {
    method: 'PUT',
    data,
    cancelToken
  });
}

export async function validatePaymentHistory(referenceId, cancelToken) {
  return request(`${serviceURL}/Payments/Resume/${referenceId}`, {
    method: 'GET',
    cancelToken
  });
}

export async function cancelPayment(caseId, cancelToken, cancelReasonId) {
  return request(`${serviceURL}/CancelAward/${caseId}/${cancelReasonId}`, {
    method: 'DELETE',
    cancelToken
  });
}

export async function cancelSale(data, cancelToken) {
  return request(`${serviceURL}/Payments/CancelSale`, {
    method: 'DELETE',
    cancelToken,
    data
  });
}

export async function updateAwarding(data, cancelToken) {
  return request(`${serviceURL}/`, {
    method: 'PUT',
    data,
    cancelToken
  });
}

export async function addMassiveAward(data){
  return request(`${serviceURL}/MassLoad`, {
    method: 'POST',
    data
  });
};

export async function validateMassiveAward(data){
  return request(`${serviceURL}/MassLoadValidate`, {
    method: 'POST',
    data
  });
}

export async function addMassiveReferences(data){
  return request(`${serviceURL}/MassLoadReferences`, {
    method: 'POST',
    data
  });
};

export async function validateMassiveReferences(data){
  return request(`${serviceURL}/MassLoadValidateReferences`, {
    method: 'POST',
    data
  });
}

export async function reportExcelPayments(data, reportType) {
  return request(`${shortServiceURL}/Reports/ExcelReport/${reportType}`, {
    method: 'POST',
    data
  });
}

