import request from './request';
const serviceURL = 'Cases/Api/v1/Damages/';

export async function saveDamages(data, cancelToken) {
  return request(`${serviceURL}`, {
    method: 'POST',
    data,
    cancelToken
  });
}

export async function getDamagesVehicle(caseId, cancelToken) {
  return request(`${serviceURL}${caseId}`, {
    method: 'GET',
    cancelToken
  });
}

export async function getCommentsDamages(caseId, cancelToken) {
  return request(`${serviceURL}Comments/${caseId}`, {
    method: 'GET',
    cancelToken
  });
}
