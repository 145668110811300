import Dashboard from 'pages/Dashboard';
import BuyerDeliveryInfo from 'pages/BuyerDeliveryInfo';
import { lazy } from 'react';
import { MassiveDocumentCommentsComponent } from 'pages/MassiveDocumentComments/MassiveDocumentComments';

const BuyersAwardingsSearch = lazy(() => import('pages/BuyersAwardingsSearch'));
const BuyerAwardingDetail = lazy(() => import('pages/BuyerAwardingDetail'));
const CheckBuyer = lazy(() => import('pages/CheckInformation/CheckBuyer'));
const Users = lazy(() => import('pages/Users'));
const MassiveUsers = lazy(() => import('pages/MassiveUsers'));
const MassiveProofs = lazy(() => import('pages/MassiveUsers/MassiveProofs'));
const CheckInsured = lazy(() => import('pages/CheckInformation/CheckInsured'));
const UpdateInsured = lazy(() => import('pages/Update/UpdateInsured'));
const CasesDetail = lazy(() => import('pages/CasesDetail'));
const CasesAttachments = lazy(() => import('pages/CasesAttachments'));
const DiversesDetail = lazy(() => import('pages/DiversesDetail'));
const Catalogs = lazy(() => import('pages/Catalogs'));
const CatalogsList = lazy(() => import('pages/CatalogsList'));
const CatalogsMarkets = lazy(() => import('pages/CatalogsMarkets'));
const CatalogsProviders = lazy(() => import('pages/CatalogsProviders'));
const CatalogsProviderOffice = lazy(() => import('pages/CatalogsProviderOffice'));
const SearchCases = lazy(() => import('pages/SearchCases'));
const UniversePT = lazy(() => import('pages/UniversePT/List'));
const UniversePTDetail = lazy(() => import('pages/UniversePT/Detail'));
const MultipleDetailsUPT = lazy(() => import('pages/UniversePT/MultipleDetails'));
const MasssiveCases = lazy(() => import('pages/MassiveCases'));
const MassivePhotos = lazy(() => import('pages/MassiveCases/MassivePhotos'));
const BuyersInvitation = lazy(() => import('pages/BuyersInvitation/BuyersInivtation'));
const SearchTransfers = lazy(() => import('pages/SearchTransfers'));
const SearchPayments = lazy(() => import('pages/SearchPayments'));
const AssignTransfer = lazy(() => import('pages/AssignTransfer'));
const DetailTransfer = lazy(() => import('modules/Transfer/containers/ReadTransfer'));
const BeginTransfer = lazy(() => import('pages/BeginTransfer'));
const UpdateTransfer = lazy(() => import('pages/UpdateTransfer'));
const SearchUsers = lazy(() => import('pages/SearchUsers'));
const AdminDetail = lazy(() => import('pages/AdminDetail'));
const InsuranceCarrierDetail = lazy(() => import('pages/InsuranceCarrierDetail'));
const ProviderDetail = lazy(() => import('pages/ProviderDetail'));
const InsuredDetail = lazy(() => import('pages/InsuredDetail'));
const BuyerDetail = lazy(() => import('pages/BuyerDetail'));
const MyProfile = lazy(() => import('pages/MyProfile'));
const UpdateDiversesDetail = lazy(() => import('pages/UpdateDiversesDetail'));
const CasesUpdate = lazy(() => import('pages/CasesUpdate'));
const SearchAdverts = lazy(() => import('pages/SearchAdverts'));
const Adverts = lazy(() => import('pages/Adverts'));
const AdvertSelection = lazy(() => import('pages/AdvertsSelection'));
const AdvertsPrePublication = lazy(() => import('pages/AdvertsPrePublication'));
const AdvertsDetail = lazy(() => import('pages/AdvertsDetail'));
const AdvertsUpdate = lazy(() => import('pages/AdvertsUpdate'));
const AdvertsUpdateWrapped = lazy(() => import('pages/AdvertsUpdateWrapped'));
const AdvertsOnLine = lazy(() => import('pages/AdvertsOnline'));
const AdvertsArchived = lazy(() => import('pages/AdvertsArchived'));
const BuyerAdvertDetail = lazy(() => import('pages/BuyerAdvertDetail'));
const AdvertResults = lazy(() => import('pages/AdvertResults'));
const SearchAwardings = lazy(() => import('pages/SearchAwardings'));
const AwardRegister = lazy(() => import('pages/AwardRegister'));
const AwardValidate = lazy(() => import('pages/AwardValidate'));
const PaymentsDetail = lazy(() => import('pages/PaymentsDetail'));
const PaymentUpdate = lazy(() => import('pages/PaymentUpdate'));
const Test2 = lazy(() => import('modules/Case'));
const Test1 = lazy(() => import('components/Test1'));
const TransferRef = lazy(() => import('modules/Transfer'));
const Compensation = lazy(() => import('modules/Compensation'));
const ReadCompensation = lazy(() => import('modules/Compensation/containers/ReadCompensation'));
const UpdateCompensation = lazy(() => import('modules/Compensation/containers/UpdateCompensation'));
const Inventory = lazy(() => import('modules/Inventory'));
const ReportTransfers = lazy(() => import('modules/Reports/containers/SearchTransfers'));
const VehicleIncomings = lazy(() => import('modules/VehiclesInComings'));
const DetailsVehicleIncoming = lazy(() => import('modules/DetailsVehicleIncoming'));
const RegisterVehicleIncoming = lazy(() => import('modules/RegisterVehicleIncoming'));
const VehicleEntries = lazy(() => import('modules/VehicleEntries'));
const VehicleEntryDetails = lazy(() => import('modules/VehicleEntryDetails'));
const VehicleCheckOut = lazy(() => import('modules/VehicleCheckOut'));
const UpdateVehicleEntries = lazy(() => import('modules/UpdateVehicleEntries'));
const RegisterOutgoingVehicle = lazy(() => import('modules/RegisterOutgoingVehicle'));
const CaseTransferDetails = lazy(() => import('modules/TransferDetails'));
const VehicleOutgoingDetails = lazy(() => import('modules/VehicleOutgoingDetails'));
const UpdateOutgoingVehicle = lazy(() => import('modules/UpdateOutgoingVehicle'));
const ReportsInventoryEntries = lazy(() => import('modules/ReportsInventoryEntries/containers'));
const CaseTransferUpdate = lazy(() => import('modules/TransferUpdate'));
const Notifications = lazy(() => import('modules/Notifications'));
const EventLog = lazy(() => import('modules/EventLog'));
const GeneralDashboard = lazy(() => import('modules/GeneralDashboard'));
const VinValidation = lazy(() => import('modules/VinValidation'));
const MassiveAward = lazy(() => import('pages/MassiveAward'));
const MassiveReferences = lazy(() => import('pages/MassiveReferences'));
const Info = lazy(() => import('pages/Info'));

const routes = [
  {
    path: '/cases/diverses/update/:id',
    component: UpdateDiversesDetail
  },

  {
    path: '/dashboard',
    component: Dashboard
  },
  {
    path: '/users/add/individual',
    exact: true,
    component: Users
  },

  {
    path: '/users/add/massive',
    exact: true,
    component: MassiveUsers
  },
  {
    path: '/users/add/proof',
    exact: true,
    component: MassiveProofs
  },

  {
    path: '/users/buyersInvitation',
    component: BuyersInvitation
  },

  {
    path: '/users/check/insured',
    exact: true,
    component: CheckInsured
  },

  {
    path: '/users/update/insured',
    exact: true,
    component: UpdateInsured
  },

  {
    path: '/users/check/buyer',
    exact: true,
    component: CheckBuyer
  },

  {
    path: '/users/validate/buyer/:id',
    exact: true,
    component: BuyerDetail
  },

  {
    path: '/users/search/:showFilter?',
    exact: true,
    component: SearchUsers
  },

  {
    path: '/users/admin/detail/:id',
    exact: true,
    component: AdminDetail
  },

  {
    path: '/users/insuranceCarrier/detail/:id',
    exact: true,
    component: InsuranceCarrierDetail
  },

  {
    path: '/users/insured/detail/:id',
    exact: true,
    component: InsuredDetail
  },

  {
    path: '/users/provider/detail/:id',
    exact: true,
    component: ProviderDetail
  },

  {
    path: '/users/buyer/detail/:id',
    exact: true,
    component: BuyerDetail
  },

  {
    path: '/account/myProfile',
    exact: true,
    component: MyProfile
  },

  {
    path: '/catalogs/add/individual',
    exact: true,
    component: Catalogs
  },

  {
    path: '/catalogs/details/',
    component: CatalogsList
  },

  {
    path: '/catalogs/markets/detail/:id',
    component: CatalogsMarkets
  },

  {
    path: '/catalogs/providerOffice/detail/:id',
    component: CatalogsProviderOffice
  },

  {
    path: '/catalogs/providers/detail/:id',
    component: CatalogsProviders
  },

  {
    path: '/cases/search/',
    component: SearchCases
  },

  {
    path: '/transfers/search/',
    component: SearchTransfers
  },

  {
    path: '/transfers/assign/:assignType/:caseId',
    component: AssignTransfer
  },

  {
    path: '/transfers/begin/:caseId/:transferId',
    component: BeginTransfer
  },

  {
    path: '/transfers/detail/:transferId',
    component: DetailTransfer
  },

  {
    path: '/transfers/update/:caseId/:transferId',
    component: UpdateTransfer
  },

  {
    path: '/cases/transfer/detail/:transferId',
    component: CaseTransferDetails
  },

  {
    path: '/cases/transfer/update/:transferId',
    component: CaseTransferUpdate
  },

  {
    path: '/cases/vehicle/detail/:id',
    component: CasesDetail
  },

  {
    path: '/cases/vehicle/update/:id',
    component: CasesUpdate
  },

  {
    path: '/cases/diverses/detail/:id',
    component: DiversesDetail
  },

  {
    path: '/cases/attachments/:id',
    exact: true,
    component: CasesAttachments
  },
  {
    path: '/cases/attachments/',
    exact: true,
    component: CasesAttachments
  },

  {
    path: '/cases/attachments/validate/:id',
    exact: true,
    component: CasesAttachments
  },

  {
    path: '/cases/add/massive',
    component: MasssiveCases
  },

  {
    path: '/cases/add/photos/:param',
    component: MassivePhotos
  },

  {
    path: '/awardings/massive/award',
    component: MassiveAward
  },

  {
    path: '/awardings/massive/reference',
    component: MassiveReferences
  },

  {
    path: '/cases/universept',
    component: UniversePT
  },

  {
    path: '/cases/add/universept/:visualserie',
    component: UniversePTDetail
  },

  {
    path: '/cases/add/multipledetailsupt',
    component: MultipleDetailsUPT
  },

  {
    path: '/adverts/search/',
    component: SearchAdverts
  },

  {
    path: '/adverts/add/',
    component: Adverts
  },

  {
    path: '/adverts/add/:advertId',
    component: Adverts
  },

  {
    exact: true,
    path: '/adverts/selection/:caseType/:advertId',
    component: AdvertSelection
  },
  {
    exact: true,
    path: '/adverts/pre/:caseType/:advertId',
    component: AdvertsPrePublication
  },

  {
    exact: true,
    path: '/adverts/detail/:advertId',
    component: AdvertsDetail
  },

  {
    exact: true,
    path: '/adverts/update/:advertId',
    component: AdvertsUpdate
  },

  {
    exact: true,
    path: '/adverts/update/selection/:caseType/:advertId',
    component: AdvertsUpdateWrapped
  },

  {
    exact: true,
    path: '/adverts/update/pre/:caseType/:advertId',
    component: AdvertsUpdateWrapped
  },

  {
    exact: true,
    path: '/adverts/list/online/:type?',
    component: AdvertsOnLine
  },

  {
    exact: true,
    path: '/adverts/list/archived/:type?',
    component: AdvertsArchived
  },

  {
    exact: true,
    path: '/adverts/buyers/detail/:advertId/:caseId/:type/:visit',
    component: BuyerAdvertDetail
  },

  {
    exact: true,
    path: '/adverts/results/vehicle/:advertId/:caseId',
    component: AdvertResults
  },

  {
    exact: true,
    path: '/adverts/results/diverses/:advertId/:caseId',
    component: AdvertResults
  },

  {
    exact: true,
    path: '/awardings/search/:filtersOpen',
    component: SearchAwardings
  },

  {
    exact: true,
    path: '/awardings/add/:caseId/:originPage',
    component: AwardRegister
  },

  {
    exact: true,
    path: '/awardings/buyers/search/:type?',
    component: BuyersAwardingsSearch
  },

  {
    exact: true,
    path: '/awardings/buyers/delivery/:caseId',
    component: BuyerDeliveryInfo
  },

  {
    exact: true,
    path: '/awardings/buyers/detail/:caseId',
    component: BuyerAwardingDetail
  },

  {
    exact: true,
    path: '/awardings/:caseId/:originPage',
    component: AwardValidate
  },

  {
    exact: true,
    path: '/payments/search/:filtersOpen?',
    component: SearchPayments
  },
  {
    exact: true,
    path: '/payments/detail/:awardId',
    component: PaymentsDetail
  },

  {
    exact: true,
    path: '/payments/update/:caseId',
    component: PaymentUpdate
  },

  {
    exact: true,
    path: '/test2',
    component: Test2
  },
  {
    exact: true,
    path: '/cases/add/individual',
    component: Test2
  },
  {
    exact: true,
    path: '/tests',
    component: Test1
  },
  {
    exact: true,
    path: '/transfer',
    component: TransferRef
  },
  {
    exact: true,
    path: '/compensation/calculate/:caseType/:caseId',
    component: Compensation
  },
  {
    exact: true,
    path: '/compensation/update/:caseType/:caseId',
    component: UpdateCompensation
  },
  {
    exact: true,
    path: '/compensation/detail/:caseType/:caseId',
    component: ReadCompensation
  },
  {
    exact: true,
    path: '/inventory/search',
    component: Inventory
  },
  {
    exact: true,
    path: '/reports/transfers',
    component: ReportTransfers
  },
  {
    exact: true,
    path: '/inventory/vehiclesIncoming',
    component: VehicleIncomings
  },
  {
    exact: true,
    path: '/inventory/incoming/detail/:transferId',
    component: DetailsVehicleIncoming
  },
  {
    exact: true,
    path: '/inventory/incoming/register/:caseId/:transferId',
    component: RegisterVehicleIncoming
  },
  {
    exact: true,
    path: '/inventory/vehicleEntries',
    component: VehicleEntries
  },
  {
    exact: true,
    path: '/inventory/vehicleEntries/detail/:inventoryId',
    component: VehicleEntryDetails
  },
  {
    exact: true,
    path: '/inventory/vehicleCheckOut',
    component: VehicleCheckOut
  },
  {
    exact: true,
    path: '/inventory/updateVehicleEntries/:inventoryId/:DocumentId',
    component: UpdateVehicleEntries
  },
  {
    exact: true,
    path: '/inventory/register/outgoingVehicle/:caseId/:inventoryId/:transferId',
    component: RegisterOutgoingVehicle
  },
  {
    exact: true,
    path: '/inventory/vehicleOutgoing/detail/:inventoryId/:transferId',
    component: VehicleOutgoingDetails
  },
  {
    exact: true,
    path: '/inventory/update/outgoingVehicle/:caseId/:inventoryId/:transferId',
    component: UpdateOutgoingVehicle
  },
  {
    exact: true,
    path: '/reports/Inventory',
    component: ReportsInventoryEntries
  },
  {
    exact: false,
    path: '/notifications',
    component: Notifications
  },
  {
    exact: true,
    path: '/eventLog',
    component: EventLog
  },
  {
    exact: true,
    path: '/general-dash',
    component: GeneralDashboard
  }
  ,
  {
    exact: true,
    path: '/vinvalidation',
    component: VinValidation
  }
  ,
  {
    exact: true,
    path: '/manuals/:id',
    component: Info
  }
  ,
  {
    exact: true,
    path: '/instructions/:id',
    component: Info
  },
  {
    exact: true,
    path: '/cases/comments/massive',
    component: MassiveDocumentCommentsComponent
  }
];

export default routes;