import request from './request';
const serviceURL = 'Catalogs/api/v1/catalogs/fixedcatalogs/';
const shortServiceURL = 'Catalogs/api/v1/catalogs/';
var qs = require('qs');

export async function listCountries() {
  return request(`${serviceURL}countries`, {
    method: 'GET'
  });
}

export async function listStatesByCountry(countryId) {
  return request(`${serviceURL}StatesByCountry/${countryId}`, {
    method: 'GET'
  });
}

export async function listLanguages() {
  return request(`${serviceURL}languages`, {
    method: 'GET'
  });
}

export async function listRegimes(payload){
  const {currentProfile : roleId, language} = payload;
  return request(`${serviceURL}regimes?roleId=${roleId}&language=${language}`, {
    method: 'GET'
  });
}

export async function listCancelReasons(language){
  return request(`${serviceURL}CancelReasons?language=${language}`, {
    method: 'GET'
  });
}

export async function getEnabledConfig(branchId){
  return request(`${shortServiceURL}Suppliers/Branches/EnableBranchConfig?branchId=${branchId}`, {
    method: 'GET'
  });
}

export async function listTimeZones() {
  return request(`${serviceURL}timeZones`, {
    method: 'GET'
  });
}

export async function listDepartments() {
  return request(`${serviceURL}departments`, {
    method: 'GET'
  });
}

export async function listJobs(internal) {
  let initial = typeof internal !== 'undefined' ? internal : true;
  return request(`${serviceURL}jobs/${initial}`, {
    method: 'GET'
  });
}

export async function statusUsers() {
  return request(`${serviceURL}userStatus`, {
    method: 'GET'
  });
}

export async function listMarkets(data) {
  return request(`${shortServiceURL}markets`, {
    method: 'GET',
    params: data
  });
}

export async function listMarketsall() {
  return request(`${shortServiceURL}markets/marketall`, {
    method: 'GET'
  });
}

export async function getMarketDetail(marketId) {
  return request(`${shortServiceURL}markets/${marketId}`, {
    method: 'GET'
  });
}

export async function listProviders() {
  return request(`${shortServiceURL}suppliers`, {
    method: 'GET'
  });
}

export async function listProviderOffice(providerId, data) {
  return request(`${shortServiceURL}suppliers/branches/${providerId}`, {
    method: 'GET',
    params: data
  });
}

export async function listProviderOfficeByBranchId(branchId, data) {
  return request(`${shortServiceURL}suppliers/branches/ /${branchId}`, {
    method: 'GET',
    params: data
  });
}

export async function listServiceType(isProviderBranch) {
  let url = `${serviceURL}serviceType/1`;
  let urlShow = `${serviceURL}serviceType/1?showByVisible=true`;
  const setUrl = isProviderBranch ? urlShow : url;
  return request(setUrl, {
    method: 'GET'
  });
}

export async function listAddressType(language = false) {
  return request(`${serviceURL}addressType${language && language.trim().length ? ("?language=" +language) : ""}`, {
    method: 'GET'
  });
}

export async function listDocumentsGroup(type) {
  let existCaseType = typeof type !== 'undefined' ? type : '';
  return request(`${serviceURL}documentGroups/${existCaseType}`, {
    method: 'GET'
  });
}

export async function listDocumentsByGroup(groupId, language = false) {
  return request(`${serviceURL}documentsByGroup/${groupId}${language && language.trim().length ? ("?language=" +language) : ""}`, {
    method: 'GET'
  });
}

export async function addCatalog(catalog, type) {
  return request(`${shortServiceURL}${type}`, {
    method: 'POST',
    data: catalog
    //handlerEnabled: false
  });
}

export async function updateCatalog(catalog, type) {
  return request(`${shortServiceURL}${type}`, {
    method: 'PUT',
    data: catalog
  });
}

export async function caseType() {
  return request(`${serviceURL}caseType`, {
    method: 'GET'
  });
}

export async function getCaseStatus() {
  return request(`${serviceURL}caseStatus`, {
    method: 'GET'
  });
}

export async function getSinesterType(cancelToken) {
  return request(`${serviceURL}WreckType`, {
    method: 'GET',
    cancelToken
  });
}

export async function getVehicleType(cancelToken) {
  return request(`${serviceURL}VehicleType`, {
    method: 'GET',
    cancelToken
  });
}

export async function getEngineType(cancelToken) {
  return request(`${serviceURL}EngineType`, {
    method: 'GET',
    cancelToken
  });
}

export async function getSinesterSubtype(cancelToken) {
  return request(`${serviceURL}WreckSubtype`, {
    method: 'GET',
    cancelToken
  });
}

export async function getSinesterOtherSubtype(cancelToken) {
  return request(`${serviceURL}OtherWreckSubtype`, {
    method: 'GET',
    cancelToken
  });
}

export async function getOfferTypes() {
  return request(`${serviceURL}OfferType`, {
    method: 'GET'
  });
}

export async function getSuppliersBranchesById(id) {
  let url = `${shortServiceURL}Suppliers/Branches`;
  let urlByService = `${shortServiceURL}Suppliers/Branches?serviceTypeId=${id}`;
  const setUrl = id !== 'null' ? urlByService : url;

  return request(setUrl, {
    method: 'GET'
  });
}

export async function getSuppliersByServiceType(serviceType, params = {}) {
  const resultantParams = { ...params, serviceTypeId: serviceType };

  return request(`${shortServiceURL}Suppliers`, {
    params: resultantParams,
    method: 'GET'
  });
}

export async function getSuppliersByRoleId(id) {
  return request(`${shortServiceURL}Suppliers?roleId=${id}`, {
    method: 'GET'
  });
}

export async function getInsuranceTypes() {
  return request(`${serviceURL}ServiceType/2`, {
    method: 'GET'
  });
}

export async function getSupplierById(supplierId) {
  return request(`${shortServiceURL}Suppliers/${supplierId}`, {
    method: 'GET'
  });
}

export async function getByService(serviceTypeId) {
  return request(`${shortServiceURL}Suppliers/GetByService/${serviceTypeId}`, {
    method: 'GET'
  });
}

export async function getServiceTypeByGroupId(groupId) {
  return request(`${serviceURL}ServiceType/${groupId}`, {
    method: 'GET'
  });
}

export async function getSuppliersServices(serviceTypeId, isProviderBranch, data) {
  let urlForBranches = `${shortServiceURL}suppliers/GetByService?services=1&services=2`;
  let url = `${shortServiceURL}suppliers/GetByService?services=1&services=2&services=3`;
  url = isProviderBranch ? urlForBranches : url;
  let urlByService = `${shortServiceURL}Suppliers/GetByService/${serviceTypeId}`;
  const setUrl = serviceTypeId !== 'null' ? urlByService : url;
  return request(setUrl, {
    method: 'GET',
    params: data
  });
}

export async function getBranchesByProviderIdAndServiceType(providerId, serviceTypeId, data) {
  let stringQuery = '';

  if (providerId && typeof providerId !== 'undefined' && typeof providerId !== 'string') {
    if (providerId.length > 1) {
      providerId.map(p => {
        if (p) stringQuery += `providerId=${p}&`;
      });
    } else {
      stringQuery = `providerId=${providerId}`;
    }
    //  stringQuery = stringQuery.slice(0, -1);
  } else if (typeof providerId === 'string') {
    stringQuery = `providerId=${providerId}`;
  }

  return request(
    `${shortServiceURL}Suppliers/Branches/Multiple?${stringQuery}&serviceTypeId=${serviceTypeId}`,
    {
      method: 'GET',
      params: data
    }
  );
}

/*  /Api/v1/Catalogs/FixedCatalogs/TransferStatus */
export async function listStatusTransfer({ cancelToken }) {
  return request(`${serviceURL}TransferStatus`, {
    method: 'GET',
    cancelToken
  });
}

export async function listProblems() {
  return request(`${serviceURL}ProblemType`, {
    method: 'GET'
  });
}

export async function listDistanceType() {
  return request(`${serviceURL}DistanceType`, {
    method: 'GET'
  });
}

export async function listLocationType() {
  return request(`${serviceURL}LocationType`, {
    method: 'GET'
  });
}

export async function getVehicleParts(cancelToken) {
  return request(`${serviceURL}VehicleParts`, {
    method: 'GET',
    cancelToken
  });
}

export async function getMarketsAttributes() {
  return request(`${serviceURL}MarketAttributes`, {
    method: 'GET'
  });
}

export async function getBranchesByServiceTypeArray(data) {
  return request(`${shortServiceURL}suppliers/branches?services=1&services=2`, {
    params: data,
    method: 'GET'
  });
}

export async function getCraneBranches(data) {
  return request(`${shortServiceURL}suppliers/branches?services=1`, {
    params: data,
    method: 'GET'
  });
}

export async function getCardPoundBranches(data) {
  return request(`${shortServiceURL}suppliers/branches?services=2`, {
    params: data,
    method: 'GET'
  });
}

export async function getSuppliersByServiceTypeArray(data) {
  return request(`${shortServiceURL}suppliers/GetByService?services=1&services=2`, {
    params: data,
    method: 'GET'
  });
}

export async function marketsByCaseTypeAndUserRol(caseTypeId) {
  return request(`${shortServiceURL}markets/?serviceTypeId=${caseTypeId}`, {
    method: 'GET'
  });
}
export async function getAdvertsTypes() {
  return request(`${serviceURL}AdvertStatus`, {
    method: 'GET'
  });
}

export async function getAdvertAttributes() {
  return request(`${serviceURL}AdvertAttributes`, {
    method: 'GET'
  });
}

export async function getPaymentsStatus() {
  return request(`${serviceURL}PaymentsStatus`, {
    method: 'GET',
    paramsSerializer: param => {
      return qs.stringify(param, { arrayFormat: 'repeat' });
    }
  });
}
