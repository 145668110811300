import request from './request';
const advertsURL = 'Adverts/Api/v1/Reports';
var qs = require('qs');

export async function reportBidsAdvert(name,data) {
  return request(`${advertsURL}/BidReport/${name}`, {
    method: 'POST',
    data,
    responseType: 'blob'
  });
}

export async function reportExcelAdvert(name, data, reportType){
  return request(`${advertsURL}/ExcelReport/${reportType}/${name}`, {
    method: 'POST',
    data
  });
}