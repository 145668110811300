import request from 'services/request';
const servicePics = 'Files/Api/v1/Pics/';

export async function getPhotosInventoryIncoming({ inventoryId, type, cancelToken, show = false }) {
  return request(`${servicePics}LastPics/Inventories/${inventoryId}/${type}/${show}/${type}`, {
    method: 'GET',
    cancelToken
  });
}

export async function markAsFavorite({ inventoryId, fileId, cancelToken, fromAll, asFavorite }) {
  return request(`${servicePics}FavoriteInventoryPic/Inventory/Favorite/${inventoryId}/${fileId}${fromAll ? "?asFavorite=" + asFavorite : ""}`, {
    method: 'PUT',
    cancelToken
  });
}
