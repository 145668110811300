import request from 'services/request';
const serviceURL = 'Users/Api/v1/Component';
var qs = require('qs');

export async function getComponents(params) {
    return request(`${serviceURL}`, {
      params,
      method: 'GET',
      paramsSerializer: param => {
        return qs.stringify(param, { arrayFormat: 'repeat' });
      }
    });
  }